import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SecondaryHeader.css';

function SecondaryHeader() {
  const [showTradingDropdown, setShowTradingDropdown] = useState(false);
  const [showStocksDropdown, setShowStocksDropdown] = useState(false);
  const [showCryptoDropdown, setShowCryptoDropdown] = useState(false);
  const [showForexDropdown, setShowForexDropdown] = useState(false);
  const [showPortfoliosDropdown, setShowPortfoliosDropdown] = useState(false);
  const [showResourcesDropdown, setShowResourcesDropdown] = useState(false);
  const [showWatchListsDropdown, setShowWatchListsDropdown] = useState(false); // New state variable

  const handleScroll = () => {
    window.scrollBy({ top: 100, behavior: 'smooth' });
  };

  const CalendarLink = ({ to, children }) => (
    <div className="calendar-link-group">
      <Link to={to} onClick={handleScroll} className="table-link">
        {children}
      </Link>
      <Link to={`${to}-mosaic`} onClick={handleScroll} className="mosaic-link">
        <i className="fa fa-th-large"></i>
      </Link>
    </div>
  );

  return (
    <nav className="secondary-header">
      <ul>
        {/* Home Menu Item */}
        <li>
          <Link to="/" className="hover:underline" onClick={handleScroll}>
            <i className="fa fa-home mr-1"></i>Home
          </Link>
        </li>

        {/* Catalysts Calendars Menu Item */}
        <li
          className="relative highlight primary-highlight"
          onMouseEnter={() => setShowTradingDropdown(true)}
          onMouseLeave={() => setShowTradingDropdown(false)}
        >
          <Link
            to="/stock-market-catalyst-calendars-home"
            className="hover:underline"
            onClick={handleScroll}
          >
            <i className="fa fa-calendar mr-1"></i>Catalysts Calendars
            {showTradingDropdown && <i className="fa fa-caret-down ml-1"></i>}
          </Link>
          {showTradingDropdown && (
            <div className="dropdown">
              <div className="calendar-subheader">
                <span>Table</span>
                <span>Mosaic</span>
              </div>
              <div className="dropdown-subtitle">Stock Catalysts</div>
              <CalendarLink to="/marketcatalysts-calendar">
                <i className="fa fa-star mr-1"></i>Market Catalysts Calendar
                <i className="fa fa-star ml-1"></i>
              </CalendarLink>
              <CalendarLink to="/earnings-calendar">Earnings Calendar</CalendarLink>
              <CalendarLink to="/conferencecall-calendar">
                Conference Call Calendar
              </CalendarLink>
              <CalendarLink to="/fda-calendar">FDA Calendar</CalendarLink>
              <CalendarLink to="/ipo-calendar">IPO Calendar</CalendarLink>
              <CalendarLink to="/dividend-calendar">Dividend Calendar</CalendarLink>
              <CalendarLink to="/splits-calendar">Splits Calendar</CalendarLink>
              <div className="dropdown-subtitle">Event Catalysts</div>
              <CalendarLink to="/conference-calendar">Conference Calendar</CalendarLink>
              <CalendarLink to="/economic-calendar">Economic Calendar</CalendarLink>
            </div>
          )}
        </li>

        {/* Portfolios Menu Item */}
        <li
          className="relative highlight primary-highlight"
          onMouseEnter={() => setShowPortfoliosDropdown(true)}
          onMouseLeave={() => setShowPortfoliosDropdown(false)}
        >
          <Link
            to="/invest/portfolios"
            className="hover:underline"
            onClick={handleScroll}
          >
            <i className="fa fa-briefcase mr-1"></i>Portfolios
            {showPortfoliosDropdown && <i className="fa fa-caret-down ml-1"></i>}
          </Link>
          {showPortfoliosDropdown && (
            <div className="dropdown">
              <div className="dropdown-subtitle">Specialty Portfolios</div>
              <Link
                to="/invest/portfolios/market-catalysts-flagship-stocks"
                onClick={handleScroll}
              >
                <i className="fa fa-star mr-1"></i>Market Catalysts Flagship Stocks
                <i className="fa fa-star ml-1"></i>
              </Link>
              <div className="dropdown-subtitle">Sector Portfolios</div>
              <Link to="/invest/portfolios/technology-stocks" onClick={handleScroll}>
                Technology Stocks
              </Link>
              <Link to="/invest/portfolios/financials-stocks" onClick={handleScroll}>
                Financials Stocks
              </Link>
              <Link
                to="/invest/portfolios/biopharma-healthcare-stocks"
                onClick={handleScroll}
              >
                Biopharma & Healthcare Stocks
              </Link>
              <Link
                to="/invest/portfolios/consumer-discretionary-stocks"
                onClick={handleScroll}
              >
                Consumer Discretionary Stocks
              </Link>
              <Link to="/invest/portfolios/industrials-stocks" onClick={handleScroll}>
                Industrials Stocks
              </Link>
              <Link
                to="/invest/portfolios/communication-services-stocks"
                onClick={handleScroll}
              >
                Communication Services Stocks
              </Link>
              <Link
                to="/invest/portfolios/consumer-staples-stocks"
                onClick={handleScroll}
              >
                Consumer Staples Stocks
              </Link>
              <Link to="/invest/portfolios/energy-stocks" onClick={handleScroll}>
                Energy Stocks
              </Link>
              <Link to="/invest/portfolios/materials-stocks" onClick={handleScroll}>
                Materials Stocks
              </Link>
              <Link to="/invest/portfolios/real-estate-stocks" onClick={handleScroll}>
                Real Estate Stocks
              </Link>
              <Link to="/invest/portfolios/utilities-stocks" onClick={handleScroll}>
                Utilities Stocks
              </Link>
            </div>
          )}
        </li>

        {/* Updated Watch Lists Menu Item */}
        <li
          className="relative highlight primary-highlight"
          onMouseEnter={() => setShowWatchListsDropdown(true)}
          onMouseLeave={() => setShowWatchListsDropdown(false)}
        >
          <Link to="/watch-lists" className="hover:underline" onClick={handleScroll}>
            <i className="fa fa-binoculars mr-1"></i>Watch Lists
            {showWatchListsDropdown && <i className="fa fa-caret-down ml-1"></i>}
          </Link>
          {showWatchListsDropdown && (
            <div className="dropdown">
              <Link to="/market-catalysts-watch-list" onClick={handleScroll}>
                Market Catalysts Watch List
              </Link>
              <Link to="/stock-watch-list" onClick={handleScroll}>
                Stock Watch List
              </Link>
              <Link to="/crypto-watch-list" onClick={handleScroll}>
                Crypto Watch List
              </Link>
            </div>
          )}
        </li>

        {/* Articles Menu Item with Icon */}
        <li className="highlight primary-highlight">
          <Link to="/articles" className="hover:underline" onClick={handleScroll}>
            <i className="fa fa-newspaper-o mr-1"></i>Articles
          </Link>
        </li>

        {/* Companies Menu Item */}
        <li>
          <Link to="/company/AAPL" className="hover:underline" onClick={handleScroll}>
            <i className="fa fa-building mr-1"></i>Companies
          </Link>
        </li>

        {/* Screener Menu Item */}
        <li>
          <Link to="/trading" className="hover:underline" onClick={handleScroll}>
            <i className="fa fa-filter mr-1"></i>Screener
          </Link>
        </li>

        {/* Stocks Menu Item */}
        <li
          className="relative"
          onMouseEnter={() => setShowStocksDropdown(true)}
          onMouseLeave={() => setShowStocksDropdown(false)}
        >
          <Link to="/trading" className="hover:underline" onClick={handleScroll}>
            <i className="fa fa-line-chart mr-1"></i>Stocks
            {showStocksDropdown && <i className="fa fa-caret-down ml-1"></i>}
          </Link>
          {showStocksDropdown && (
            <div className="dropdown">
              <Link to="/market-overview" onClick={handleScroll}>
                Market Overview
              </Link>
              <Link to="/market-data" onClick={handleScroll}>
                Market Data
              </Link>
              <Link to="/stock-market" onClick={handleScroll}>
                Stock Market
              </Link>
              <Link to="/stock-heatmap" onClick={handleScroll}>
                Stock Heatmap
              </Link>
            </div>
          )}
        </li>

        {/* Crypto Menu Item */}
        <li
          className="relative"
          onMouseEnter={() => setShowCryptoDropdown(true)}
          onMouseLeave={() => setShowCryptoDropdown(false)}
        >
          <Link to="/crypto-market" className="hover:underline" onClick={handleScroll}>
            <i className="fa fa-bitcoin mr-1"></i>Crypto
            {showCryptoDropdown && <i className="fa fa-caret-down ml-1"></i>}
          </Link>
          {showCryptoDropdown && (
            <div className="dropdown">
              <Link to="/crypto-market" onClick={handleScroll}>
                Crypto Market
              </Link>
              <Link to="/crypto-heatmap" onClick={handleScroll}>
                Crypto Heatmap
              </Link>
            </div>
          )}
        </li>

        {/* Forex Menu Item */}
        <li
          className="relative"
          onMouseEnter={() => setShowForexDropdown(true)}
          onMouseLeave={() => setShowForexDropdown(false)}
        >
          <Link to="/forex-cross-rate" className="hover:underline" onClick={handleScroll}>
            <i className="fa fa-money mr-1"></i>Forex
            {showForexDropdown && <i className="fa fa-caret-down ml-1"></i>}
          </Link>
          {showForexDropdown && (
            <div className="dropdown">
              <Link to="/forex-cross-rate" onClick={handleScroll}>
                Forex Cross Rate
              </Link>
              <Link to="/forex-heatmap" onClick={handleScroll}>
                Forex Heatmap
              </Link>
            </div>
          )}
        </li>

        {/* Resources Menu Item */}
        <li
          className="relative highlight primary-highlight"
          onMouseEnter={() => setShowResourcesDropdown(true)}
          onMouseLeave={() => setShowResourcesDropdown(false)}
        >
          <Link to="/resources" className="hover:underline" onClick={handleScroll}>
            <i className="fa fa-book mr-1"></i>Resources
          </Link>
          {showResourcesDropdown && (
            <div className="dropdown">
              <div className="dropdown-subtitle">
                <Link to="/catalysts-guide" onClick={handleScroll}>
                  Catalyst Guides Home
                </Link>
              </div>
              <Link to="/market-catalysts-guide" onClick={handleScroll}>
                Market Catalysts Guide
              </Link>
              <Link to="/stock-catalysts-guide" onClick={handleScroll}>
                Stock Catalysts Guide
              </Link>
              <Link to="/crypto-catalysts-guide" onClick={handleScroll}>
                Crypto Catalysts Guide
              </Link>
              <Link to="/earnings-catalysts-guide" onClick={handleScroll}>
                Earnings Catalysts Guide
              </Link>
              <Link to="/fda-catalysts-calendar-guide" onClick={handleScroll}>
                FDA Catalysts Guide
              </Link>
              <Link to="/geopolitical-catalysts-guide" onClick={handleScroll}>
                Geopolitical Catalysts Guide
              </Link>
              <Link to="/world-event-catalysts-guide" onClick={handleScroll}>
                World Event Catalysts Guide
              </Link>
              <Link to="/ipo-catalysts-guide" onClick={handleScroll}>
                IPO Catalysts Guide
              </Link>
              <Link to="/dividend-catalysts-guide" onClick={handleScroll}>
                Dividend Catalysts Guide
              </Link>
              <Link to="/stock-split-catalysts-guide" onClick={handleScroll}>
                Stock Split Catalysts Guide
              </Link>
              <Link to="/conference-catalysts-guide" onClick={handleScroll}>
                Conference Catalysts Guide
              </Link>
              <Link to="/economic-catalysts-guide" onClick={handleScroll}>
                Economic Catalysts Guide
              </Link>
              <Link to="/trading-catalysts-guide" onClick={handleScroll}>
                Trading Catalysts Guide
              </Link>
              <Link to="/options-strategies-guide" onClick={handleScroll}>
                Options Strategies Guide
              </Link>
            </div>
          )}
        </li>
      </ul>
    </nav>
  );
}

export default SecondaryHeader;
